import React, { useState, useContext } from "react"
import { Context } from "../../context"
import classes from "./Navbar.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
import Whatsapp from "../../assets/whatsapp.svg"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Facebook from "../../assets/facebook.svg"
import Instagram from "../../assets/insta.svg"
import Twitter from "../../assets/twitter.svg"
import Youtube from "../../assets/youtube.svg"

const Navigation = () => {
  const [expanded, setExpanded] = useState(false)
  const { toggleModal } = useContext(Context)

  const handleEnter = e => {
    if (e.key === "Enter") {
      setExpanded(false)
    }
  }

  return (
    <Navbar
      bg="light"
      expand="xl"
      fixed="top"
      className={classes.navigation}
      expanded={expanded}
    >
      <Link to="/" className="navbar-brand">
        <img
          className={classes.logo}
          src={require("../../images/logo4.png")}
          alt="Logo"
        />
      </Link>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#inicio"
              className="nav-link"
              activeClassName="active"
            >
              Inicio
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#especialidades"
              className="nav-link"
              activeClassName="active"
            >
              Especialidades
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#ubicacion"
              className="nav-link"
              activeClassName="active"
            >
              Ubicación
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#fotos"
              className="nav-link"
              activeClassName="active"
            >
              Fotos
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#opiniones"
              className="nav-link"
              activeClassName="active"
            >
              Opiniones
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#experiencia"
              className="nav-link"
              activeClassName="active"
            >
              Experiencia
            </AnchorLink>
          </div>
          <div
            onClick={() => setExpanded(false)}
            onKeyDown={handleEnter}
            role="button"
            tabIndex="0"
          >
            <AnchorLink
              to="/#contacto"
              className="nav-link"
              activeClassName="active"
            >
              Contacto
            </AnchorLink>
          </div>
        </Nav>
        <div className={classes.social}>
          <a href="https://www.facebook.com/drclaudiomaldonado" target="blank">
            <Facebook />
          </a>
          <a
            href="https://www.instagram.com/drclaudiomaldonado/"
            target="blank"
          >
            <Instagram />
          </a>
          <a href="https://www.youtube.com/watch?v=wNSaxJ6G4Vc" target="blank">
            <Youtube />
          </a>
          <a href="https://twitter.com/drmaldonadoclau/" target="blank">
            <Twitter />
          </a>
        </div>
      </Navbar.Collapse>
      <button
        className={classes.textButton}
        id="reservar"
        onClick={toggleModal}
      >
        <span>Reservar Turno</span>
        <Whatsapp />
      </button>
    </Navbar>
  )
}

export default Navigation
