import React, { useContext } from "react"
import { Context } from "../context"
import Modal from "./Modal/Modal"
import Navbar from "./Navbar/Navbar"
import Footer from "./ContactSection/ContactSection"
import "./layout.css"

const Layout = ({ children }) => {
  const { modal } = useContext(Context)
  return (
    <>
      {modal ? <Modal /> : null}
      <div className="layout">
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  )
}

export default Layout
