import React, { createContext, useState } from "react"

const defaultState = {
  modal: false,
  toggleModal: () => {},
}

export const Context = createContext(defaultState)

const ContextProvider = props => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(prevState => !prevState)
  }

  return (
    <Context.Provider value={{ modal, toggleModal }}>
      {props.children}
    </Context.Provider>
  )
}

export default ContextProvider
