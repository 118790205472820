import React, { Fragment, useContext } from "react"
import { Context } from "../../context"
import classes from "./Modal.module.css"
import Button from "react-bootstrap/Button"
import Ubicacion from "../../assets/pin.svg"
import Telefono from "../../assets/phone.svg"
import Whatsapp from "../../assets/whatsapp.svg"
import Close from "../../assets/close.svg"

const Modal = () => {
  const { toggleModal } = useContext(Context)
  return (
    <Fragment>
      <div className={classes.backdrop} onClick={toggleModal} />
      <div className={classes.root}>
        <div className={classes.title}>
          <h2>Reservar Turno</h2>
          <Close className={classes.closeIcon} onClick={toggleModal} />
        </div>
        <div className={classes.scroll}>
          <div className={classes.consultorio}>
            <h3>
              Consultorio <span>S.M. de Tucumán</span>
            </h3>
            <div>
              <div className={classes.item}>
                <Ubicacion />
                Salta 465
              </div>
              <div className={classes.item}>
                <Telefono />
                <div>
                  Reserve su turno llamando al telefono:
                  <br />
                  <span>+54 381 545 4203</span>
                  <br />
                  O a través de WhatsApp: <br />
                </div>
              </div>
              <a
                href="https://api.whatsapp.com/send?phone=5493815454203"
                target="blank"
              >
                <Button variant="outline-secondary" className={classes.heroBtn}>
                  <span className={classes.reservar}>Reservar Turno</span>
                  <Whatsapp />
                </Button>
              </a>
            </div>
          </div>
          <div className={classes.consultorio}>
            <h3>
              Consulta <span>Online</span>
            </h3>
            <div>
              <div className={classes.item}>
                <Telefono />
                <div>
                  Reserve su consulta online llamando al telefono:
                  <br />
                  <span>+54 381 201 5367</span>
                  <br />
                  O a través de WhatsApp: <br />
                </div>
              </div>
              <a
                href="https://api.whatsapp.com/send?phone=5493812015367"
                target="blank"
              >
                <Button variant="outline-secondary" className={classes.heroBtn}>
                  <span className={classes.reservar}>Reservar Turno</span>
                  <Whatsapp />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Modal
