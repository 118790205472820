import React, { useContext } from "react"
import { Context } from "../../context"
import classes from "./ContactSection.module.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Facebook from "../../assets/facebook.svg"
import Instagram from "../../assets/instagram.svg"
import Twitter from "../../assets/twitter.svg"
import Youtube from "../../assets/youtube.svg"
import WhatsApp from "../../assets/whatsapp-flat.svg"
import Telefono from "../../assets/telefono.svg"
import Ubicacion from "../../assets/alfiler.svg"
import Reloj from "../../assets/reloj.svg"
import Mail from "../../assets/correo.svg"
import Whatsapp from "../../assets/whatsapp.svg"

const ContactSection = () => {
  const { toggleModal } = useContext(Context)

  const handleEnter = e => {
    if (e.key === "Enter") {
      toggleModal()
    }
  }

  return (
    <footer className={classes.root} id="contacto">
      <div className={classes.info}>
        <div className={classes.container}>
          <h4>Contacto</h4>
          <div className={classes.item}>
            <Telefono />
            <span>+54 381 4217191</span>
          </div>
          <div className={classes.item}>
            <Telefono />
            <span>+54 381 4212358</span>
          </div>
          <div className={classes.item}>
            <Mail />
            <span>cnmaldonados@gmail.com</span>
          </div>
          <div className={classes.item}>
            <Mail />
            <span>drcmaldonado@hotmail.com</span>
          </div>
        </div>
        <div className={classes.container}>
          <h4>Ubicación</h4>
          <div className={classes.item}>
            <Ubicacion />
            <span>
              Instituto Campbell
              <br />
              Salta 465, S.M. de Tucuman.
            </span>
          </div>
          <div>
            <br />
          </div>
        </div>
        <div className={classes.container}>
          <h4>Horarios</h4>
          <div className={[classes.item, classes.horario].join(" ")}>
            Lunes a Viernes:
          </div>
          <div className={classes.item}>
            <Reloj />
            <span>
              <span style={{ paddingLeft: "0.5rem" }}>9:00 a 13:00 hs</span>
              <br />
              16:00 a 20:00 hs
            </span>
          </div>
          {/* <div className={[classes.item, classes.horario].join(" ")}>
            9:00 a 13:00 hs
          </div>
          <div className={[classes.item, classes.horario].join(" ")}>
            16:00 a 20:00 hs
          </div> */}
        </div>
      </div>
      <Form
        className={classes.contactForm}
        method="POST"
        action="/success"
        data-netlify="true"
        name="contacto"
        autoComplete="off"
      >
        <input type="hidden" name="form-name" value="contacto" />
        <Button
          className={classes.reservarTurno}
          variant="outline-light"
          onClick={toggleModal}
        >
          <span>Reservar Turno</span>
          <Whatsapp />
        </Button>
        <h4
          id="review"
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            borderBottom: "1px solid #fff",
            marginBottom: "2rem",
          }}
        >
          Deje su opinión
        </h4>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Su Nombre</Form.Label>
          <Form.Control type="name" placeholder="" required name="nombre" />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Su Email</Form.Label>
          <Form.Control type="email" placeholder="" required name="email" />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Opinión</Form.Label>
          <Form.Control as="textarea" rows="" required name="opinion" />
        </Form.Group>
        <Button variant="outline-light" type="submit">
          Enviar
        </Button>
      </Form>
      <div className={classes.social}>
        <a href="https://www.facebook.com/drclaudiomaldonado" target="blank">
          <Facebook />
        </a>
        <a href="https://www.instagram.com/drclaudiomaldonado/" target="blank">
          <Instagram />
        </a>
        <div
          tabIndex="0"
          onClick={toggleModal}
          onKeyDown={handleEnter}
          role="button"
        >
          <WhatsApp />
        </div>
        <a href="https://www.youtube.com/watch?v=wNSaxJ6G4Vc" target="blank">
          <Youtube />
        </a>
        <a href="https://twitter.com/drmaldonadoclau/" target="blank">
          <Twitter />
        </a>
      </div>
    </footer>
  )
}

export default ContactSection
